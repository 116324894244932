html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  z-index: -1;
  background-color: #181D25;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
}
.tree-1{
  z-index: 0;
  position: fixed;
  align-self: flex-end;
  justify-content: flex-start;
  top: 70vh;
  height: 100%;
  width: 100%;
}
.birds{
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  
}

.type-container{
  align-self: center;
  justify-self: center;
  z-index: 2;
  display: flex;
  width: 100%;
  justify-content: center;
}
.navbar{
  z-index: 3;
  position: fixed;
  top: 0px;
  height: auto;
  width: 80%;
  text-align: start;
  margin-left: 10%;
  align-self: flex-start;
  justify-self: flex-start;
  font-size: 2rem;
  justify-content: flex-start;
  font-family: 'Zilla Slab', serif;
  color: #45FFCA;
}
.footer{
  text-decoration: none;
  width: 100%;
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: center;
}
.linkedin{
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
}
.instagram{
  text-decoration: none;
  cursor: pointer;
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
}

.github{
  text-decoration: none;
  cursor: pointer;
  margin-left: 30px;
    display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
}
.img{
  margin-left: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

