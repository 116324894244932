.info-paragraph{
    font-family: 'Martian Mono', monospace;
    color: grey;
    font-size: 1rem;
    width: 100%;
    height: 100%;
}
.info-main-div{
    width: 80%;
    height: 60%;
    margin-top: 10%;
    margin-bottom: 20%;
    align-self: center;
    transition: z-index 5s;

}